export default [{
        url: "/dashboards",
        name: "Dashboards",
        icon: "mdi mdi-view-dashboard",
        i18n: "Dashboards",
        index: 1,
        child: [{
                url: '/dashboards/classic-dashboard',
                name: "Classic",
                icon: "mdi mdi mdi-adjust",
                i18n: "Classic",
                index: 1.1,
            },
            {
                url: '/dashboards/analytical-dashboard',
                name: "Analytical",
                icon: "mdi mdi mdi-adjust",
                i18n: "Analytical",
                index: 1.2,
            },
            {
                url: '/dashboards/ecommerce-dashboard',
                name: "Ecommerce",
                icon: "mdi mdi mdi-adjust",
                i18n: "Ecommerce",
                index: 1.3,
            },
            {
                url: '/dashboards/general-dashboard',
                name: "General",
                icon: "mdi mdi mdi-adjust",
                i18n: "General",
                index: 1.4,
            }
        ]
    },
    {
        title: "Apps",
        i18n: "Apps",
    },
    {
        url: "/apps/chat",
        name: "Chats",
        icon: "mdi mdi-comment-processing-outline",
        i18n: "Chats",
        index: 2.1
    },
    {
        url: "/apps/calendar",
        name: "Calendar",
        icon: "mdi mdi-calendar",
        i18n: "Calendar",
        index: 2.2
    },
    {
        url: "/apps/contact",
        name: "Contact List",
        icon: "mdi mdi-account-circle",
        i18n: "ContactList",
        index: 2.3
    },
    {
        url: "/apps/contact-grid",
        name: "Contact Grid",
        icon: "mdi mdi-pencil-box-outline",
        i18n: "ContactGrid",
        index: 2.4
    },
    {
        url: "/apps/email",
        name: "Email",
        icon: "mdi mdi-email",
        i18n: "Email",
        index: 2.5
    },
    {
        url: "/apps/todo",
        name: "Todo",
        icon: "mdi mdi-bulletin-board",
        i18n: "Todo",
        index: 2.6
    },
    {
        title: "Ui Elements",
        i18n: "UiElements",
    },
    {
        url: "/component",
        name: "Vuesax Components",
        icon: "mdi mdi-credit-card-multiple",
        i18n: "VuesaxComponents",
        index: 3,
        child: [{
                url: "/component/alert",
                name: "Alert",
                icon: "mdi mdi-credit-card-scan",
                i18n: "Alert",
                index: 3.1,
            },
            {
                url: "/component/avatar",
                name: "Avatar",
                icon: "mdi mdi-account-circle",
                i18n: "Avatar",
                index: 3.2,
            },
            {
                url: "/component/breadcrumb",
                name: "Breadcrumb",
                icon: "mdi mdi-altimeter",
                i18n: "Breadcrumb",
                index: 3.3,
            },
            {
                url: "/component/buttons",
                name: "Buttons",
                icon: "mdi mdi-toggle-switch",
                i18n: "Buttons",
                index: 3.4,
            },
            {
                url: "/component/cards",
                name: "Cards",
                icon: "mdi mdi-animation",
                i18n: "Cards",
                index: 3.5,
            },
            {
                url: "/component/chips",
                name: "Chips",
                icon: "mdi mdi-blur-linear",
                i18n: "Chips",
                index: 3.6,
            },
            {
                url: "/component/collapse",
                name: "Collapse",
                icon: "mdi mdi-buffer",
                i18n: "Collapse",
                index: 3.7,
            },
            {
                url: "/component/dialog",
                name: "Dialog",
                icon: "mdi mdi-bandcamp",
                i18n: "Dialog",
                index: 3.8,
            },
            {
                url: "/component/divider",
                name: "Divider",
                icon: "mdi mdi-barcode",
                i18n: "Divider",
                index: 3.9,
            },
            {
                url: "/component/dropdown",
                name: "Dropdown",
                icon: "mdi mdi-cube-unfolded",
                i18n: "Dropdown",
                index: 31.1,
            },
            {
                url: "/component/list",
                name: "List",
                icon: "mdi mdi-code-equal",
                i18n: "List",
                index: 31.2,
            },
            {
                url: "/component/loading",
                name: "Loading",
                icon: "mdi mdi-reload",
                i18n: "Loading",
                index: 31.3,
            },
            {
                url: "/component/navbar",
                name: "Navbar",
                icon: "mdi mdi-image-filter-frames",
                i18n: "Navbar",
                index: 31.4,
            },
            {
                url: "/component/notification",
                name: "Notification",
                icon: "mdi mdi-image-filter",
                i18n: "Notification",
                index: 31.5,
            },
            {
                url: "/component/number-input",
                name: "Number input",
                icon: "mdi mdi-numeric-1-box-multiple-outline",
                i18n: "Numberinput",
                index: 31.6,
            },
            {
                url: "/component/pagination",
                name: "Pagination",
                icon: "mdi mdi-priority-high",
                i18n: "Pagination",
                index: 31.7,
            },
            {
                url: "/component/popup",
                name: "Popup",
                icon: "mdi mdi-tablet",
                i18n: "Popup",
                index: 31.8,
            },
            {
                url: "/component/progress",
                name: "Progress",
                icon: "mdi mdi-poll",
                i18n: "Progress",
                index: 31.9,
            },
            {
                url: "/component/sidebar",
                name: "Sidebar",
                icon: "mdi mdi-collage",
                i18n: "Sidebar",
                index: 32.1,
            },
            {
                url: "/component/slider",
                name: "Slider",
                icon: "mdi mdi-view-carousel",
                i18n: "Slider",
                index: 32.2,
            },
            {
                url: "/component/tabs",
                name: "Tabs",
                icon: "mdi mdi-sort-variant",
                i18n: "Tabs",
                index: 32.3,
            },
            {
                url: "/component/tooltip",
                name: "Tooltip",
                icon: "mdi mdi-image-filter-vintage",
                i18n: "Tooltip",
                index: 32.4,
            }
        ]
    },
    {
        title: "Forms",
        i18n: "Forms",
    },
    {
        url: "/form-elements",
        name: "Form Elements",
        icon: "mdi mdi-checkbox-multiple-blank-outline",
        i18n: "FormElements",
        index: 4,
        child: [{
                url: "/form-elements/checkbox",
                name: "Checkboxes",
                icon: "mdi mdi-shape-plus",
                i18n: "Checkboxes",
                index: 4.1,
            },
            {
                url: "/form-elements/datepicker",
                name: "Datepicker",
                icon: "mdi mdi-calendar-multiple-check",
                i18n: "Datepicker",
                index: 4.2,
            },
            {
                url: "/form-elements/input",
                name: "Input",
                icon: "mdi mdi-priority-low",
                i18n: "Input",
                index: 4.3,
            },
            {
                url: "/form-elements/radio",
                name: "Radio",
                icon: "mdi mdi-switch",
                i18n: "Radio",
                index: 4.4,
            },
            {
                url: "/form-elements/select",
                name: "Select",
                icon: "mdi mdi-relative-scale",
                i18n: "Select",
                index: 4.5,
            },
            {
                url: "/form-elements/switch",
                name: "Switch",
                icon: "mdi mdi-toggle-switch-off",
                i18n: "Switch",
                index: 4.6,
            },
            {
                url: "/form-elements/textarea",
                name: "Textarea",
                icon: "mdi mdi-rounded-corner",
                i18n: "Textarea",
                index: 4.7,
            },
            {
                url: "/form-elements/upload",
                name: "Upload",
                icon: "mdi mdi-select-all",
                i18n: "Upload",
                index: 4.8,
            }
        ],
    },
    {
        url: "/form-layouts",
        name: "Form Layouts",
        icon: "mdi mdi-content-duplicate",
        i18n: "FormLayouts",
        index: 5,
        child: [{
                url: "/form-layouts/form-action",
                name: "Form Action",
                icon: "mdi mdi-code-greater-than",
                i18n: "FormAction",
                index: 5.1,
            },
            {
                url: "/form-layouts/form-basic",
                name: "Form Basic",
                icon: "mdi mdi-vector-difference-ba",
                i18n: "FormBasic",
                index: 5.2,
            },
            {
                url: "/form-layouts/form-horizontal",
                name: "Form Horizontal",
                icon: "mdi mdi-file-document-box",
                i18n: "FormHorizontal",
                index: 5.3,
            }
        ],
    },
    {
        url: "/forms/form-wizard",
        name: "Form Wizard",
        icon: "mdi mdi-arrange-send-backward",
        i18n: "FormWizard",
        index: 5.4
    },
    {
        url: "/extra-components/drag-n-drop",
        name: "Drag n Drop",
        icon: "mdi mdi-drag",
        i18n: "DragnDrop",
        index: 5.5
    },
    {
        title: "Tables",
        i18n: "Tables",
    },
    {
        url: "/component/table",
        name: "Table Examples",
        icon: "mdi mdi-border-none",
        i18n: "TableExamples",
        index: 6,
        child: [{
                url: "/tables/basic-table",
                name: "Basic table",
                icon: "mdi mdi-border-style",
                i18n: "Basictable",
                index: 6.1,
            },
            {
                url: "/tables/editable-datatable",
                name: "Editable table",
                icon: "mdi mdi-border-all",
                i18n: "Editabletable",
                index: 6.2,
            },
            {
                url: "/tables/expand-datatable",
                name: "Expand Datatable",
                icon: "mdi mdi-border-left",
                i18n: "ExpandDatatable",
                index: 6.3,
            },
            {
                url: "/tables/filter-table",
                name: "Filter table",
                icon: "mdi mdi-border-inside",
                i18n: "Filter table",
                index: 6.4,
            },
            {
                url: "/tables/miscellaneous-table",
                name: "Miscellaneous table",
                icon: "mdi mdi-border-vertical",
                i18n: "Miscellaneoustable",
                index: 6.5,
            },
            {
                url: "/tables/pagination-table",
                name: "Pagination table",
                icon: "mdi mdi-border-top",
                i18n: "Paginationtable",
                index: 6.6,
            },
            {
                url: "/tables/selected-table",
                name: "Selected table",
                icon: "mdi mdi-border-vertical",
                i18n: "Selectedtable",
                index: 6.7,
            },
            {
                url: "/tables/sst-table",
                name: "SST table",
                icon: "mdi mdi-tab-unselected",
                i18n: "SSTtable",
                index: 6.8,
            },
        ],
    },
    {
        title: "Charts",
        i18n: "Charts",
    }, {
        url: "/charts/apexcharts",
        name: "Apex Charts",
        icon: "mdi mdi-image-filter-tilt-shift",
        i18n: "ApexCharts",
        index: 7
    },
    {
        url: "/charts/chartjs",
        name: "ChartJs",
        icon: "mdi mdi-chart-areaspline",
        i18n: "ChartJs",
        index: 8
    },
    {
        title: "Icons",
        i18n: "Icons",
    }, {
        url: "/icons/material-icons",
        name: "Material Icons",
        icon: "mdi mdi-vector-square",
        i18n: "MaterialIcons",
        index: 9
    },
    {
        url: "/icons/themify-icons",
        name: "Themify Icons",
        icon: "mdi mdi-vector-triangle",
        i18n: "ThemifyIcons",
        index: 10
    },
    {
        title: "Extra Components",
        i18n: "ExtraComponents",
    },
    {
        url: "/extra-components/carousel",
        name: "Carousel",
        icon: "mdi mdi-view-carousel",
        i18n: "Carousel",
        index: 12
    },
    {
        url: "/starterkit",
        name: "Starterkit",
        icon: "mdi mdi-airplay",
        i18n: "Starterkit",
        index: 15
    },
    {
        title: "Widgets",
        i18n: "Widgets",
    }, {
        url: "/widgets/widget-apps",
        name: "App Widgets",
        icon: "mdi mdi-grid",
        i18n: "AppWidgets",
        index: 13
    }, {
        url: "/widgets/widget-data",
        name: "Data Widgets",
        icon: "mdi mdi-view-grid",
        i18n: "DataWidgets",
        index: 14
    }
]